.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.candidateForm {
  height: 100%;
  overflow: scroll;
  padding: 0 0.533333rem 1.333333rem 0.533333rem;
  box-sizing: border-box;
}

.candidateForm .candidateForm-content {
  padding: 0.266667rem 0 0 0;
}

.candidateForm .candidateForm-content .employInfo .menue-block:not(:first-child) {
  margin-top: 1.333333rem;
}

.candidateForm .candidateForm-content .employInfo .parentInfo .menueItem {
  font-size: 0.48rem;
  font-weight: 600;
}

.candidateForm .candidateForm-content .employInfo .parentInfo .menueItem:before {
  content: '| ';
  font-size: 0.533333rem;
  font-weight: 600;
  color: #1890ff;
}

.candidateForm .candidateForm-content .employInfo .childInfo {
  display: flex;
  padding: 0.32rem 0;
  font-size: 0.426667rem;
  margin-top: 0.266667rem;
  color: #666;
  border-bottom: 0.026667rem solid #ebedf0;
}

.candidateForm .candidateForm-content .employInfo .childInfo .childInfo-lable {
  flex: 3;
}

.candidateForm .candidateForm-content .employInfo .childInfo .childInfo-content {
  flex: 5;
}

.candidateForm .candidateForm-content .attachfo .menue-block {
  margin-top: 1.333333rem;
}

.candidateForm .candidateForm-content .attachfo .parentInfo {
  font-size: 0.48rem;
  font-weight: 600;
}

.candidateForm .candidateForm-content .attachfo .parentInfo .menueItem:before {
  content: '| ';
  font-size: 0.533333rem;
  font-weight: 600;
  color: #1890ff;
}

.candidateForm .candidateForm-content .attachfo .childInfo {
  display: flex;
  flex-direction: row;
  padding: 0.32rem 0;
  font-size: 0.426667rem;
  margin-top: 0.266667rem;
  color: #666;
  border-bottom: 0.026667rem solid #ebedf0;
}

.candidateForm .candidateForm-content .attachfo .childInfo .childInfo-lable {
  flex: 3;
}

.candidateForm .candidateForm-content .attachfo .childInfo .childInfo-content {
  flex: 5;
  overflow: scroll;
}

.candidateForm .candidateForm-content .attachfo .childInfo .childInfo-content img {
  width: 100%;
  background-size: cover;
}

.candidateForm .candidateForm-content .attachfo .childInfo .childInfo-content video {
  width: 100%;
}

.candidateForm .candidateForm-content .attachfo .childInfo .childInfo-content .downLoadText {
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #1890ff;
  color: #1890ff;
  cursor: pointer;
}

.candidateForm .no-data-wrapper {
  padding: 20vh;
}

.candidateForm .no-data-wrapper .no-data-text {
  text-align: center;
}

.candidateForm .no-data-wrapper .img-wrapper {
  width: 80%;
  margin: 0.533333rem auto 0 auto;
}

.candidateForm .no-data-wrapper .img-wrapper img {
  width: 100%;
}